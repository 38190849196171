.container-polling {
    margin-top: 60px;
    text-align: center;
}

.container-polling > .h4-judul {
    font-weight: bolder;
}

.card-image {
    align-items: center;
    box-shadow: 2px 2px 5px orange;
    max-width: 18rem;
    max-height: 18rem;
    padding: 35px;
    margin: 20px auto;
}

.card-image:hover {
    box-shadow: 0 0 2px 1px rgba(5, 11, 14, 0.5);
    -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.card-image > .image-okay {
    padding: 30px;
    max-width: 10px;
}

.image-okay:hover {
    -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.button-image {
    border: none;
    background-color: inherit;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.button-image:hover {
    background: #eee;
    color: blue;
}